body {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

.app-background {
  background: linear-gradient(180deg, #151330 0%, #202454 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special-button {
  background: linear-gradient(142.62deg, #464496 12.17%, #5A58B4 87.33%);
  box-shadow: 0px 6px 12px 3px rgba(0, 0, 0, 0.2), inset 1px 1px 1px 1px rgba(255, 255, 255, 0.15);
}

.footer-div {
  background: linear-gradient(271.67deg, #46347E 0.5%, #423883 43.75%, #302672 99.68%);
}

.marketing-hero-text {
  font-weight: 900;
  font-size: 44px;
  line-height: 44px;
  letter-spacing: -0.5%;
}

.marketing-subtitle-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.5%;
}

.marketing-use-web-button-text {
  color: #D4A9EC;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

.paywall-duration-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.paywall-description-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.7;
}

.paywall-description-subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;

  text-align: center;

  color: #D1C5F9;
}

.paywall-price-text {

}

.start-payment-button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.welcome-hero-text {
  font-weight: 900;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: -0.005em;
}

.wecome-body-text {
  font-size: 16px;
  line-height: 24px;
}

.paywall-hero-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.005em;

  color: #FFFFFF;
}

.paywall-feature-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #D1C5F9;
}

.paywall-best-value-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #1C1D45;

}

.interpret-dream-button {
  background: linear-gradient(142.62deg, #464496 12.17%, #5A58B4 87.33%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.interpret-dream-button:disabled {
  opacity: 0.5;
}

textarea.dream-input {
  /* font-size: 22px; */
  letter-spacing: -0.02em;
}

textarea.dream-input::placeholder {
  color: rgba(214, 208, 240, 0.8);
}

textarea.dream-input::selection {
  background: #5956B2; /* WebKit/Blink Browsers */
}

textarea.dream-input::-moz-selection {
  background: #5956B2; /* Gecko Browsers */
}

.rotating-loading-gem {
  animation: rotation 2.5s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dream-text {
  font-size: 14px;
  line-height: 20px;
  color: #D1C5F9;
}

.interpretation-text {
  font-size: 14px;
  line-height: 20px;
}

.interpretation-hero-text {
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
}

.dream-log-entry-date {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.dream-log-entry-text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
   font-size: 14px;
   line-height: 20px;
}

.share-button {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@keyframes success {
  from {
    background-color: green;
  }
  to {
    background-color: green;
  }
}

.share-button-success {
  animation: success 5s linear;
}

.account-dropdown {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.account-dropdown-option {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #191A3D;
}

.need-help-button {
  background: linear-gradient(142.62deg, #464496 12.17%, #5A58B4 87.33%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.need-help-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #FFFFFF;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
